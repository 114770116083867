@charset "UTF-8";

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after {
  content: '';
  content: none;
}

q:before,
q:after {
  content: '';
  content: none;
}

textarea::-webkit-scrollbar-track,
.msg-record::-webkit-scrollbar-track,
.small-table::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-track,
.page-content::-webkit-scrollbar-track,
.mt-item .mt-body::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #eee;
  border-radius: 5px;
  border: 3px solid #fff;
  box-sizing: border-box;
}

#interviewContent::placeholder {
    color: #BDBDBD;
    opacity: 1;
}

#interviewContent::-webkit-input-placeholder {
    color: #BDBDBD;
}

#interviewContent::-moz-placeholder {
    color: #BDBDBD;
}

#interviewContent::-ms-input-placeholder {
    color: #BDBDBD;
}

#interviewContent:-ms-input-placeholder {
    color: #BDBDBD;
}


textarea::-webkit-scrollbar,
.msg-record::-webkit-scrollbar,
.small-table::-webkit-scrollbar,
.scrollbar::-webkit-scrollbar,
.page-content::-webkit-scrollbar,
.mt-item .mt-body::-webkit-scrollbar {
  width: 8px;
  background-color: #eee;
  border-radius: 5px;
  margin-right: 20px;
}

textarea::-webkit-scrollbar-thumb,
.msg-record::-webkit-scrollbar-thumb,
.small-table::-webkit-scrollbar-thumb,
.scrollbar::-webkit-scrollbar-thumb,
.page-content::-webkit-scrollbar-thumb,
.mt-item .mt-body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
  border: none;
}

.small-table ul,
.mt-item .mt-body ul {
  display: block;
  padding: 0;
  margin: 0;
  position: relative;
}

.small-table li,
.mt-item .mt-body li {
  display: flex;
  border-bottom: 1px solid #E0E0E0;
  color: #616161;
  padding: 5px;
  font-size: 14px;
}

.small-table li>div,
.mt-item .mt-body li>div {
  padding-right: 10px;
  text-align: left;
}

.small-table li .flex-1,
.mt-item .mt-body li .flex-1 {
  flex: 1;
  text-align: center;
}

.small-table li .flex-2,
.mt-item .mt-body li .flex-2 {
  flex: 3;
}

.small-table li .flex-3,
.mt-item .mt-body li .flex-3 {
  flex: 3;
}

.small-table li .flex-4,
.mt-item .mt-body li .flex-4 {
  flex: 4;
}

.small-table li .flex-5,
.mt-item .mt-body li .flex-5 {
  flex: 5;
}

.small-table li .flex-6,
.mt-item .mt-body li .flex-6 {
  flex: 6;
}

.small-table li a,
.mt-item .mt-body li a {
  color: #62B8EE;
  text-decoration: underline;
}

.small-table li.field,
.mt-item .mt-body li.field {
  background-color: #EEEEEE;
  position: sticky;
  top: 0;
}

.eye:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-image: url("../images/layout/iconset.svg");
  background-size: auto 100%;
}

.form-item.search .value:before,
.list-table .lt-row .alert:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  -webkit-mask-image: url("../images/layout/iconset.svg");
  -webkit-mask-size: auto 100%;
}

.icon-0:before {
  -webkit-mask-position: 0% 0;
}

.icon-1:before {
  -webkit-mask-position: 1.69% 0;
}

.icon-2:before {
  -webkit-mask-position: 3.39% 0;
}

.icon-3:before {
  -webkit-mask-position: 5.08% 0;
}

.icon-4:before {
  -webkit-mask-position: 6.78% 0;
}

.icon-5:before {
  -webkit-mask-position: 8.47% 0;
}

.icon-6:before {
  -webkit-mask-position: 10.17% 0;
}

.icon-7:before {
  -webkit-mask-position: 11.86% 0;
}

.icon-8:before {
  -webkit-mask-position: 13.56% 0;
}

.icon-9:before {
  -webkit-mask-position: 15.25% 0;
}

.icon-10:before {
  -webkit-mask-position: 16.95% 0;
}

.icon-11:before {
  -webkit-mask-position: 18.64% 0;
}

.icon-12:before {
  -webkit-mask-position: 20.34% 0;
}

.icon-13:before {
  -webkit-mask-position: 22.03% 0;
}

.icon-14:before {
  -webkit-mask-position: 23.73% 0;
}

.icon-15:before {
  -webkit-mask-position: 25.42% 0;
}

.icon-16:before {
  -webkit-mask-position: 27.12% 0;
}

.icon-17:before {
  -webkit-mask-position: 28.81% 0;
}

.icon-18:before {
  -webkit-mask-position: 30.51% 0;
}

.icon-19:before {
  -webkit-mask-position: 32.2% 0;
}

.icon-20:before {
  -webkit-mask-position: 33.9% 0;
}

.icon-21:before {
  -webkit-mask-position: 35.59% 0;
}

.icon-22:before {
  -webkit-mask-position: 37.29% 0;
}

.icon-23:before {
  -webkit-mask-position: 38.98% 0;
}

.icon-24:before {
  -webkit-mask-position: 40.68% 0;
}

.icon-25:before {
  -webkit-mask-position: 42.37% 0;
}

.icon-26:before {
  -webkit-mask-position: 44.07% 0;
}

.icon-27:before {
  -webkit-mask-position: 45.76% 0;
}

.icon-28:before {
  -webkit-mask-position: 47.46% 0;
}

.icon-29:before {
  -webkit-mask-position: 49.15% 0;
}

.icon-30:before {
  -webkit-mask-position: 50.85% 0;
}

.icon-31:before {
  -webkit-mask-position: 52.54% 0;
}

.icon-32:before {
  -webkit-mask-position: 54.24% 0;
}

.icon-33:before {
  -webkit-mask-position: 55.93% 0;
}

.icon-34:before {
  -webkit-mask-position: 57.63% 0;
}

.icon-35:before {
  -webkit-mask-position: 59.32% 0;
}

.icon-36:before {
  -webkit-mask-position: 61.02% 0;
}

.icon-37:before {
  -webkit-mask-position: 62.71% 0;
}

.icon-38:before {
  -webkit-mask-position: 64.41% 0;
}

.icon-39:before {
  -webkit-mask-position: 66.1% 0;
}

.icon-40:before {
  -webkit-mask-position: 67.8% 0;
}

.icon-41:before {
  -webkit-mask-position: 69.49% 0;
}

.icon-42:before {
  -webkit-mask-position: 71.19% 0;
}

.icon-43:before {
  -webkit-mask-position: 72.88% 0;
}

.icon-44:before {
  -webkit-mask-position: 74.58% 0;
}

.icon-45:before {
  -webkit-mask-position: 76.27% 0;
}

.icon-46:before {
  -webkit-mask-position: 77.97% 0;
}

.icon-47:before {
  -webkit-mask-position: 79.66% 0;
}

.icon-48:before {
  -webkit-mask-position: 81.36% 0;
}

.icon-49:before {
  -webkit-mask-position: 83.05% 0;
}

.icon-50:before {
  -webkit-mask-position: 84.75% 0;
}

.icon-51:before {
  -webkit-mask-position: 86.44% 0;
}

.icon-52:before {
  -webkit-mask-position: 88.14% 0;
}

.icon-53:before {
  -webkit-mask-position: 89.83% 0;
}

.icon-54:before {
  -webkit-mask-position: 91.53% 0;
}

.icon-55:before {
  -webkit-mask-position: 93.22% 0;
}

.icon-56:before {
  -webkit-mask-position: 94.92% 0;
}

.icon-57:before {
  -webkit-mask-position: 96.61% 0;
}

.icon-58:before {
  -webkit-mask-position: 98.31% 0;
}

.icon-59:before {
  -webkit-mask-position: 100% 0;
}

.icon-60:before {
  -webkit-mask-position: 101.69% 0;
}

.icon {
  font-size: 0;
}

.icon:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  -webkit-mask-image: url("../images/layout/iconset.svg");
  -webkit-mask-size: auto 100%;
  width: 28px;
  height: 28px;
  background-color: #62B8EE;
  transition: background-color 0.3s ease;
}

.icon.alert:before {
  background-color: #F37402;
}

.icon.orange:before {
  background-color: #F37402;
}

.icon.forest-green:before {
  background-color: #228B22;
}

.icon.w2:before {
  width: 60px;
}

.icon.small-mg {
  margin-right: 3px;
}

.icon.small-mg:before {
  width: 24px;
  height: 24px;
}

.icon-before:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-image: url("../images/layout/iconset.svg");
}

.icon-after:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-image: url("../images/layout/iconset.svg");
}

.fz-hero {
  font-size: 36px;
  font-weight: 400;
  display: block;
  line-height: 1.125em;
}

@media (min-width: 1380px) {
  .fz-hero {
    font-size: 48px;
  }
}

.fz-A {
  font-size: 26px;
  font-weight: 400;
  display: block;
  line-height: 1.25em;
  margin-bottom: 2vw;
}

@media (min-width: 1380px) {
  .fz-A {
    font-size: 32px;
  }
}

.fz-B {
  font-size: 1.375rem;
  font-weight: 400;
  display: block;
  line-height: 1.25em;
  margin-bottom: 2vw;
}

@media (min-width: 1380px) {
  .fz-B {
    font-size: 1.5rem;
  }
}

@media (min-width: 1700px) {
  .fz-B {
    font-size: 1.75rem;
  }
}

.list-table .lt-row.ovv {
  overflow: visible;
}

.fz-B.has-border {
  padding-left: 0.75em;
  border-left: 5px solid #62B8EE;
}

@media (min-width: 1380px) {
  .fz-B.has-border {
    border-left: 8px solid #62B8EE;
  }
}

.fz-B.aic {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fz-C,
.block-area .form-item .field {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.25em;
}

@media (min-width: 1380px) {

  .fz-C,
  .block-area .form-item .field {
    font-size: 18px;
  }
}

@media (min-width: 1700px) {

  .fz-C,
  .block-area .form-item .field {
    font-size: 20px;
  }
}

.fz-C {
  display: block;
  margin-bottom: 2vw;
}

.fz-D {
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 1.25em;
  margin-bottom: 1em;
}

.fz-small {
  font-size: 0.75em;
}

@media (min-width: 1380px) {
  .fz-small {
    font-size: 0.8125em;
  }
}

.fz-middle {
  font-size: 0.8125em;
}

@media (min-width: 1380px) {
  .fz-middle {
    font-size: 0.875em;
  }
}

.fc-lightgray {
  color: #616161;
}

.fc-black {
  color: #424242;
}

.fc-realblack {
  color: #000;
}

.fw-bold {
  font-weight: 500;
}

.fc-disabled {
  color: #BDBDBD;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-weight: 500;
  padding: 0 1.5em;
  box-sizing: border-box;
  border: 2px solid #62B8EE;
  background-color: #62B8EE;
  color: #fff;
  border-radius: 32px;
  white-space: nowrap;
  justify-content: center;
  transition: all 0.15s ease;
  transition-property: background-color, border-color, color, transform;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  height: 36px;
  font-size: 0.9375rem;
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
}

.btn+.btn {
  margin-left: 0.5em;
}

@media (min-width: 576px) {
  .btn+.btn {
    margin-left: 1em;
  }
}

.btn span {
  font-weight: 500;
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  line-height: 1em;
  transition: all 0.15s ease;
}

.btn.disabled {
  cursor: not-allowed;
  background-color: #E0E0E0;
  border-color: #E0E0E0;
  pointer-events: none;
  color: #b5b5b5;
}

.btn.disabled .icon:before {
  background-color: #b5b5b5;
}

.btn.disabled span {
  color: #BDBDBD;
  color: #b5b5b5;
}

@media (hover: hover) {
  .btn.disabled:hover {
    background-color: #E0E0E0;
    border-color: #E0E0E0;
    color: #b5b5b5;
  }

  .btn.disabled:hover .icon:before {
    background-color: #b5b5b5;
  }

  .btn.disabled:hover span {
    color: #b5b5b5;
  }
}

@media (min-width: 576px) {
  .btn {
    padding: 0 2em;
  }
}

@media (min-width: 1180px) {
  .btn {
    height: 42px;
    font-size: 1rem;
  }
}

@media (min-width: 1380px) {
  .btn {
    height: 48px;
    font-size: 1.125rem;
  }
}

@media (min-width: 980px) {
  .btn {
    min-width: 180px;
  }
}

.btn span {
  display: block;
  white-space: nowrap;
  transition: color 0.35s ease;
  pointer-events: none;
  box-sizing: border-box;
}

.btn:hover,
.btn:active {
  background-color: #1D436F;
  border-color: #1D436F;
}

.btn.btn-border {
  background-color: #FAFAFA;
  color: #62B8EE;
}

.btn.btn-border:hover,
.btn.btn-border:active {
  background-color: #E4F2FA;
  border-color: #62B8EE;
}

.btn.btn-border:hover span,
.btn.btn-border:active span {
  color: #62B8EE;
}

.btn.btn-small {
  border-radius: 10px;
  height: 28px;
  padding: 0 0.75em;
  min-width: auto;
  font-size: 14px;
}

@media (min-width: 1180px) {
  .btn.btn-small {
    height: 28px;
    font-size: 14px;
  }
}

@media (min-width: 1380px) {
  .btn.btn-small {
    height: 28px;
    font-size: 14px;
  }
}

@media (min-width: 1700px) {
  .btn.btn-small {
    height: 28px;
    font-size: 14px;
  }
}

.btn.btn-small span {
  font-weight: normal;
}

.btn.btn-small+.btn-icon {
  margin-left: 30px;
}

.btn.btn-middle {
  border-radius: 10px;
  height: 32px;
  padding: 0 0.9em;
  min-width: auto;
  font-size: 14px;
}

@media (min-width: 1180px) {
  .btn.btn-middle {
    height: 32px;
    font-size: 14px;
  }
}

@media (min-width: 1380px) {
  .btn.btn-middle {
    height: 32px;
    font-size: 16px;
  }
}

@media (min-width: 1700px) {
  .btn.btn-middle {
    height: 36px;
    font-size: 16px;
  }
}

.btn.btn-middle span {
  font-weight: normal;
}

.btn.btn-middle+.btn-icon {
  margin-left: 30px;
}

@media (hover: hover) {
  .btn.btn-middle:hover {
    background-color: #1D436F;
    border-color: #1D436F;
  }
}

.btn.btn-icon {
  height: 36px;
  padding: 0;
  min-width: auto;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-icon.rotate {
  transform: rotate(180deg);
}

@media (min-width: 1180px) {
  .btn.btn-icon {
    height: 42px;
    width: 42px;
  }
}

@media (min-width: 1380px) {
  .btn.btn-icon {
    height: 48px;
    width: 48px;
  }
}

@media (min-width: 1700px) {
  .btn.btn-icon .icon:before {
    width: 30px;
    height: 30px;
  }
}

@media (hover: hover) {
  .btn.btn-icon:hover .icon:before {
    background-color: #1D436F;
  }
}

.btn.btn-large {
  height: 32px;
  padding: 0 1.5em;
  border-radius: 15px;
}

.btn.btn-large span {
  font-weight: normal;
}

@media (min-width: 1180px) {
  .btn.btn-large {
    height: 40px;
  }
}

@media (min-width: 1380px) {
  .btn.btn-large {
    height: 48px;
  }
}

.btn.btn-dark {
  background-color: #1D436F;
  border-color: #1D436F;
}

.btn.btn-dark:hover,
.btn.btn-dark:active {
  background-color: #000;
  border-color: #000;
}

.btn.btn-dark:hover span,
.btn.btn-dark:active span {
  color: #fff;
}

.btn.btn-icon-text {
  background-color: transparent;
  color: #62B8EE;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.btn.btn-icon-text .icon {
  margin-right: 8px;
}

@media (min-width: 1380px) {
  .btn.btn-icon-text .icon:before {
    width: 30px;
    height: 30px;
  }
}

@media (hover: hover) {
  .btn.btn-icon-text:hover span {
    color: #1D436F;
  }

  .btn.btn-icon-text:hover .icon:before {
    background-color: #1D436F;
  }
}

.btn.mgl {
  margin-left: 8px;
}

.btn.btn-text {
  background-color: transparent;
  color: #62B8EE;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.btn.btn-text span {
  border-bottom: 1px solid #62B8EE;
}

.btn.btn-text.disabled {
  cursor: not-allowed;
}

.btn.btn-text.disabled span {
  border: none;
}

.btn.btn-text:hover,
.btn.btn-text:active {
  background-color: transparent;
  color: #94CAED;
}

.btn.btn-text:hover span,
.btn.btn-text:active span {
  color: #94CAED;
  border-color: #94CAED;
}

.btn.clean-width {
  max-width: initial;
  min-width: initial;
}

.btn.full {
  width: 100%;
  max-width: 100%;
}

input,
textarea,
select {
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: 1px solid #BDBDBD;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  height: 32px;
  box-sizing: border-box;
  background-color: #fff;
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  color: #757575;
}

input.right,
textarea.right,
select.right {
  text-align: right;
}

input.center,
textarea.center,
select.center {
  text-align: center;
}

input.radius,
textarea.radius,
select.radius {
  border-radius: 20px !important;
}

input.full,
textarea.full,
select.full {
  width: 100%;
}

input.small,
textarea.small,
select.small {
  width: 40% !important;
  min-width: 120px;
}

input.max-120,
textarea.max-120,
select.max-120 {
  width: 120px !important;
}

input.error,
textarea.error,
select.error {
  border-color: #F37402;
  border-width: 2px;
}

input.error::placeholder,
textarea.error::placeholder,
select.error::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F37402;
  opacity: 1;
  /* Firefox */
}

input.error:-ms-input-placeholder,
textarea.error:-ms-input-placeholder,
select.error:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #F37402;
}

input.error::-ms-input-placeholder,
textarea.error::-ms-input-placeholder,
select.error::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #F37402;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #1D436F;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

input:disabled,
textarea:disabled,
select:disabled {
  background-color: rgba(224, 224, 224, 0.6);
  color: #888;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: not-allowed;
  opacity: 1;
}

input.fc-orange,
textarea.fc-orange,
select.fc-orange {
  color: #F37402 !important;
}

input.no-border,
textarea.no-border,
select.no-border {
  border: none;
  background-color: transparent !important;
}

input.no-border:focus,
textarea.no-border:focus,
select.no-border:focus {
  box-shadow: none;
}

input.no-border.disabled,
textarea.no-border.disabled,
select.no-border.disabled {
  background-color: transparent;
}

input.no-border-but-bg,
textarea.no-border-but-bg,
select.no-border-but-bg {
  border: none;
}

input.no-border-but-bg:focus,
textarea.no-border-but-bg:focus,
select.no-border-but-bg:focus {
  box-shadow: none;
}

@media (min-width: 1700px) {

  input,
  textarea,
  select {
    height: 36px;
  }
}

input:read-only,
textarea:read-only {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: not-allowed;
}

input.text-right,
textarea.text-right {
  text-align: right;
}

textarea {
  resize: none;
  border-radius: 15px;
  height: auto;
  padding: 0.5em;
  box-sizing: border-box;
  width: 100%;
}

textarea::-webkit-scrollbar {
  background-color: transparent;
}

textarea::-webkit-scrollbar-track {
  margin: 4px;
}

@media (min-width: 1380px) {
  textarea {
    padding: 0.5em 1em;
  }
}

select {
  border-radius: 20px;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  background: #fff url("../images/layout/arrow-select.svg") no-repeat right 12px center;
  background-size: 13px auto;
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

@media (min-width: 1380px) {
  select {
    background: #fff url("../images/layout/arrow-select.svg") no-repeat right 16px center;
    background-size: 15px auto;
  }
}

select.error {
  color: #F37402;
}

select.error:focus {
  border-color: #F37402;
}

select.error option {
  color: #616161;
}

select.no-border {
  border: none !important;
  background-color: transparent;
}

select.no-border:hover,
select.no-border:focus,
select.no-border:active {
  border: none !important;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="search"],
input[type="file"],
input[type="month"] {
  border-radius: 20px;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

input[type="radio"]+label,
input[type="checkbox"]+label {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 28px;
}

input[type="radio"]+label:before,
input[type="checkbox"]+label:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #BDBDBD;
  flex-shrink: 0;
  margin-right: 0.5em;
  border-radius: 3px;
  transition: all 0.3s ease;
  transition-property: border-color, background-color;
  background-color: #fff;
}

@media (min-width: 1700px) {

  input[type="radio"]+label:before,
  input[type="checkbox"]+label:before {
    width: 24px;
    height: 24px;
  }
}

input[type="radio"]+label.orange,
input[type="checkbox"]+label.orange {
  background-color: #F37402;
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  width: max-content;
  font-size: 16px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

input[type="radio"]+label.dark-blue:before,
input[type="checkbox"]+label.dark-blue:before {
  border-color: #1d72a7;
}

input[type="radio"]:checked+label,
input[type="checkbox"]:checked+label {
  color: #000;
}

input[type="radio"]:checked+label[for="f4521-1"],
input[type="radio"]:checked+label[for="f4521-2"],
input[type="radio"]:checked+label[for="f4521-3"] {
  color: #424242;
}

input[type="radio"]:checked+label:before,
input[type="checkbox"]:checked+label:before {
  background: #F37402 url("../images/layout/check.svg") no-repeat center center;
  border-color: #F37402;
  background-size: 70% auto;
  background-size: auto 12px;
}

@media (min-width: 1700px) {

  input[type="radio"]:checked+label:before,
  input[type="checkbox"]:checked+label:before {
    background-size: auto 14px;
  }
}

input[type="radio"]:checked+label.orange,
input[type="checkbox"]:checked+label.orange {
  color: #fff;
  background-color: #62B8EE;
}

input[type="radio"]:checked+label.orange:before,
input[type="checkbox"]:checked+label.orange:before {
  background-color: #62B8EE;
  border-color: #62B8EE;
}

input[type="radio"]:checked+label.dark-blue:before,
input[type="checkbox"]:checked+label.dark-blue:before {
  background-color: #F37402;
  border-color: #1d72a7;
}

input[type="radio"]:checked:disabled+label:before,
input[type="checkbox"]:checked:disabled+label:before {
  background-color: #F37402;
}

input[type="radio"]:disabled+label,
input[type="checkbox"]:disabled+label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: not-allowed;
}

input[type="radio"]:disabled+label:before,
input[type="checkbox"]:disabled+label:before {
  background-color: rgba(224, 224, 224, 0.6);
  color: #888;
}

input[type="radio"]+label:before {
  border-radius: 50%;
}

.multi-form {
  display: flex;
  align-items: center;
  border: 1px solid #BDBDBD;
}

.multi-form .line {
  width: 1px;
  display: inline-flex;
  align-items: center;
  height: 32px;
  margin: 0 3px;
}

.multi-form .line:before {
  content: "";
  display: block;
  width: 1px;
  height: 80%;
  border-left: 1px solid #E0E0E0;
}

.multi-form input,
.multi-form select {
  margin: 0;
  border: none;
  flex: 1;
  border-radius: 0;
  box-shadow: none;
}


.multi-form input:focus,
.multi-form select:focus {
  box-shadow: none;
}

.multi-form.rd {
  border-radius: 20px;
  overflow: hidden;
}

.eye {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.eye:before {
  width: 28px;
  height: 28px;
  background-size: auto 100%;
  background-position: 57.63% 0;
}

.eye.active:before {
  background-size: auto 100%;
  background-position: 55.93% 0;
}

.form-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  color: #424242;
  width: 100%;
  position: relative;
}

@media (min-width: 1180px) {
  .form-item {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.form-item.in-table {
  margin: 0;
}

.form-item.in-table .help-word {
  height: 22px;
}

.form-item.in-table.center label {
  margin: 0 auto;
}

.form-item.title .fz-B {
  margin-left: 0 !important;
}

.form-item.independent {
  margin-left: 0;
  margin-right: 0;
}

.form-item.half {
  width: 100%;
}

@media (min-width: 1180px) {
  .form-item.half {
    width: calc(50% - 30px);
  }
}

.form-item.three {
  width: 100%;
}

@media (min-width: 1180px) {
  .form-item.three {
    width: calc(33.33% - 30px);
  }
}

.form-item.four {
  width: 100%;
}

@media (min-width: 1180px) {
  .form-item.four {
    width: calc(50% - 30px);
  }
}

@media (min-width: 1380px) {
  .form-item.four {
    width: calc(25% - 30px);
  }
}

@media (min-width: 740px) {
  .form-item.w60 {
    width: 60%;
  }
}

@media (min-width: 740px) {
  .form-item.w80 {
    width: 80%;
  }
}

.form-item.jcfe {
  justify-content: flex-end;
}

.form-item.aifs {
  align-items: flex-start;
}

.form-item.disabled .field {
  color: #888;
}

.form-item.read-only input {
  background: none;
  padding-left: 0;
  border: none;
}

.form-item.read-only input:focus {
  box-shadow: none;
}

.form-item .fake-upload {
  position: relative;
}

.form-item .fake-upload .real {
  position: absolute;
  opacity: 0;
}

.form-item .list-table {
  width: 100%;
  margin-top: 15px;
}

.form-item .list-table .list-table {
  margin-top: 0;
}

.form-item .help-word {
  width: 100%;
  flex-shrink: 0;
  font-size: 0.75rem;
  padding-left: 130px;
  opacity: 0;
  transition: opacity 0.35s ease;
  visibility: hidden;
  pointer-events: none;
  color: #F37402;
}

.form-item .help-word.right {
  justify-content: flex-end;
  text-align: right;
}

.form-item .remark-word {
  width: 100%;
  flex-shrink: 0;
  font-size: 0.75rem;
  padding-left: 130px;
  transition: opacity 0.35s ease;
  right: 0;
  bottom: 0;
  position: absolute;
  color: #757575;
  justify-content: flex-end;
  text-align: right;
}

.form-item .extra-info {
  position: absolute;
  background-color: #BDBDBD;
  border-radius: 5px;
  right: 0;
  bottom: -12px;
  font-size: 14px;
  color: #fff;
  padding: 0 0.75em;
}

.form-item .field {
  width: 130px;
  flex-shrink: 0;
  font-weight: 500;
}

@media (min-width: 740px) {
  .form-item .field {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 980px) {
  .form-item .field {
    width: 115px;
  }
}

@media (min-width: 1700px) {
  .form-item .field {
    width: 130px;
  }
}

.form-item .field.jcsb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-item .value {
  width: calc(100% - 130px);
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

@media (min-width: 980px) {
  .form-item .value {
    width: calc(100% - 115px);
  }
}

@media (min-width: 1700px) {
  .form-item .value {
    width: calc(100% - 130px);
  }
}

.form-item .value>div {
  margin-right: 1em;
}

.form-item .value>div.btn-area {
  margin-right: 0;
  margin-left: 1em;
  justify-content: space-between;
  align-items: flex-start;
}

.form-item .value>div.btn-area .info {
  font-size: 14px;
  color: #424242;
  margin-top: -0.75em;
  padding-left: 1em;
}

@media (min-width: 1700px) {
  .form-item .value>div.btn-area .info {
    padding-left: 1.5em;
  }
}

.form-item .value>div.btn-area.jcfe {
  justify-content: flex-end;
}

.form-item .value input+div {
  margin-left: 1em;
  margin-right: 0;
}

.form-item .value.tidy>div {
  width: 33.33%;
}

@media (min-width: 1700px) {
  .form-item .value.tidy>div {
    width: 25%;
  }
}

.form-item .value.multi-inline {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
}

.form-item .value.multi-inline>div,
.form-item .value.multi-inline>input,
.form-item .value.multi-inline>select {
  flex: auto;
}

.form-item .value.multi-inline>input {
  flex: 5;
}

.form-item .value.multi-inline>input+select {
  flex: 3;
  margin-left: 10px;
}

.form-item .value.multi-inline>input+.btn {
  margin-left: 10px;
}

.form-item .value.multi-inline .btn-area {
  flex: 1;
  width: max-content;
}

.form-item .value.multi-inline .info {
  background-color: #BDBDBD;
  padding: 0 0.75em;
  border-radius: 8px;
  margin-left: 1em;
  margin-right: 0;
  color: #fff;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  font-weight: normal;
  text-align: center;
}

.form-item .value.multi-inline .info.currency-item {
  min-width: 42px;
  min-height: 28px;
}

.form-item .value.multi-inline .center {
  text-align: center;
}

.form-item .value.multi-inline .flex-1 {
  flex: 1;
}

.form-item .value.multi-inline .flex-2 {
  flex: 2;
}

.form-item .value.multi-inline .flex-3 {
  flex: 3;
}

.form-item .value.multi-inline .flex-4 {
  flex: 4;
}

.form-item .value.multi-inline .flex-5 {
  flex: 5;
}

.form-item .value.multi-inline .flex-6 {
  flex: 6;
}

.form-item .value.multi-inline .flex-7 {
  flex: 7;
}

.form-item .value.multi-inline .flex-auto {
  flex: initial;
}

.form-item .value.multi-inline .last {
  margin-right: 0;
}

.form-item .value.multi-inline.jcfs {
  justify-content: flex-start;
}

.form-item .value.multi-inline.jcsb {
  justify-content: space-between;
}

.form-item .value.multi-inline .box {
  display: flex;
  align-items: center;
  margin: 0;
  flex: initial;
  width: min-content;
}

.form-item .value.multi-inline .box.currency-box {
  margin: 3px 6px 3px 0;
}

/* .form-item .value.multi-inline .box+.box {
  margin-left: 1em;
} */

.form-item .value.multi-inline.impwarp {
  flex-wrap: wrap;
}

@media (min-width: 740px) and (max-width: 1179px) {
  .form-item .value.multi-inline.wrap {
    flex-wrap: wrap;
    justify-content: flex-start !important;
  }

  .form-item .value.multi-inline.wrap .box {
    margin: 5px 2em 5px 0;
  }

  .form-item .value.multi-inline.wrap .box+.box {
    margin-left: 0;
  }
}

.form-item .value .password {
  position: relative;
  width: 100%;
  margin: 0;
}

.form-item .value .password .eye {
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.form-item.auto-field .field {
  width: max-content;
}

.form-item.auto-field .value {
  width: auto;
  flex: 1;
  padding-left: 1em;
}

.form-item.middle-field .field {
  width: 80px;
}

.form-item.middle-field .value {
  width: calc(100% - 80px);
}

@media (min-width: 1180px) {
  .form-item.auto-field-xl .field {
    width: max-content;
  }

  .form-item.auto-field-xl .value {
    width: auto;
    flex: 1;
    padding-left: 1em;
  }
}

.form-item .datalist {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  position: absolute;
  z-index: 9;
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
  max-height: 200px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  top: 32px;
  pointer-events: none;
  opacity: 0;
}

@media (min-width: 1700px) {
  .form-item .datalist {
    top: 36px;
  }
}

.form-item .datalist li {
  padding: 0.35em 1em;
  font-size: 0.9375rem;
  display: flex;
  cursor: pointer;
}

@media (min-width: 740px) and (max-width: 1699px) {
  .form-item .datalist li {
    font-size: 0.875rem;
  }
}

.form-item .datalist li>span {
  flex-shrink: 0;
  pointer-events: none;
  line-height: 1.25em;
  display: block;
  padding-right: 20px;
  word-break: break-word;
}

.form-item .datalist li>span:last-of-type {
  padding-right: 0;
}

.form-item .datalist li>span.bold {
  font-weight: 500;
}

.form-item .datalist li>span.flex-1 {
  flex: 1;
}

.form-item .datalist li>span.flex-2 {
  flex: 2;
}

.form-item .datalist li>span.flex-3 {
  flex: 3;
}

.form-item .datalist li>span.flex-4 {
  flex: 4;
}

.form-item .datalist li>span.flex-5 {
  flex: 5;
}

.form-item .datalist li:hover,
.form-item .datalist li:active {
  background-color: rgba(98, 184, 238, 0.2);
}

.form-item .datalist.for-en li {
  font-size: 0.8125rem;
  padding: 0.5em 1em;
}

.form-item .datalist.for-en li>span {
  line-height: 1.1em;
}

.form-item .datalist.for-en li .hide-en {
  display: none !important;
}

@media (min-width: 740px) and (max-width: 1699px) {
  .form-item .datalist.for-en li {
    font-size: 0.75rem;
  }
}

.form-item.has-datalist.show-datalist .datalist {
  pointer-events: auto;
  opacity: 1;
}

.form-item.has-datalist ul.show-datalist.datalist {
  pointer-events: auto;
  opacity: 1;
}

.form-item.aifs {
  align-items: flex-start;
}

.form-item.col {
  flex-direction: column;
}

.form-item.col .field {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.form-item.col .field .fn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form-item.col .value {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-item.col .value img {
  max-width: 160px;
  height: auto;
  display: block;
  margin: 20px auto 0;
}

.form-item.col .value textarea {
  margin-top: 8px;
}

.form-item.col.for-c12 .value {
  padding-top: 12px;
}

.form-item.col.for-c12 .value>div {
  margin-bottom: 8px;
  width: calc(25% - 1em);
}

.form-item.col.aifs {
  align-items: flex-start;
}

.form-item.col.aifs .value {
  justify-content: flex-start;
}

.form-item.small-field .field {
  width: 48px;
}

.form-item.small-field .value {
  width: calc(100% - 48px);
}

.form-item .notification {
  color: #fff;
  font-size: 15px;
  text-align: center;
  width: 100%;
  margin-bottom: 28px;
}

.form-item.no-field input,
.form-item.no-field select {
  padding-left: 1em !important;
  width: 100%;
}

.form-item.no-field .value {
  width: 100%;
}

.form-item.no-field .field {
  display: none;
}

.form-item.no-field .help-word {
  padding-left: 10px;
}

.form-item.no-help-word .help-word {
  display: none;
}

.form-item.inline .help-word {
  display: flex;
  align-items: center;
  padding-left: 0;
}

@media (min-width: 1380px) {
  .form-item.inline .help-word {
    font-size: 0.875em;
  }
}

.form-item.inline .help-word .icon {
  margin-right: 10px;
}

.form-item.inline .help-word .icon:before {
  width: 18px;
  height: 18px;
}

@media (min-width: 980px) {
  .form-item.short {
    width: calc(100% - 150px) !important;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.form-item.feedback {
  padding-top: 30px;
}

.form-item.feedback .value {
  display: flex;
  justify-content: flex-end;
}

.form-item.lg {
  position: relative;
  margin-bottom: 28px;
}

.form-item.lg input,
.form-item.lg select {
  height: 48px;
  border-radius: 12px;
  padding-left: 70px;
}

@media (min-width: 1380px) {

  .form-item.lg input,
  .form-item.lg select {
    height: 56px;
    border-radius: 15px;
    font-size: 1.125rem;
  }
}

.form-item.lg .value {
  width: 100%;
}

.form-item.lg .field {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  width: 60px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1380px) {
  .form-item.lg .field {
    height: 56px;
  }
}

.form-item.lg .field:after {
  content: "";
  display: block;
  width: 1px;
  border-right: 1px solid #BDBDBD;
  right: 0;
  top: 10%;
  height: 80%;
  position: absolute;
}

.form-item.lg .help-word {
  position: absolute;
  right: 0;
  font-size: 16px;
  width: max-content;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 1em;
}

.form-item.lg.has-fn .help-word {
  right: 2em;
}

.form-item.must .field {
  position: relative;
}

.form-item.must .field:before {
  content: "✽";
  color: #F37402;
  letter-spacing: 0;
  position: absolute;
  transform: translateX(-100%);
}

.form-item.show-help .help-word {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.form-item.show-help .remark-word {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.form-item.no-help .help-word {
  white-space: nowrap;
}

.form-item.no-field .field {
  display: none;
}

.form-item.no-field .value {
  width: 100%;
}

.form-item.error .help-word {
  color: #F37402;
}

.form-item.error input[type="text"],
.form-item.error input[type="email"],
.form-item.error input[type="tel"],
.form-item.error input[type="number"],
.form-item.error input[type="password"],
.form-item.error input[type="date"],
.form-item.error input[type="search"],
.form-item.error input[type="file"],
.form-item.error input[type="month"] {
  border-color: #F37402;
  color: #F37402;
}

.form-item.error input[type="text"]:focus,
.form-item.error input[type="email"]:focus,
.form-item.error input[type="tel"]:focus,
.form-item.error input[type="number"]:focus,
.form-item.error input[type="password"]:focus,
.form-item.error input[type="date"]:focus,
.form-item.error input[type="search"]:focus,
.form-item.error input[type="file"]:focus,
.form-item.error input[type="month"]:focus {
  border-color: #F37402;
}

.form-item.error input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F37402;
  opacity: 1;
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  font-weight: normal;
  /* Firefox */
}

.form-item.error input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #F37402;
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.form-item.error input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #F37402;
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.form-item.error textarea {
  border-color: #F37402;
  color: #F37402;
}

.form-item.error textarea:focus {
  border-color: #F37402;
}

.form-item.error textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F37402;
  opacity: 1;
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  font-weight: normal;
  /* Firefox */
}

.form-item.error textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #F37402;
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.form-item.error textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #F37402;
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.form-item.error select {
  border-color: #F37402;
}

.form-item.error .dropmenu-container .dropmenu-value {
  border-color: #F37402;
  color: #F37402;
}

.form-item.search .value {
  width: 100%;
  position: relative;
}

.form-item.search .value input {
  padding-left: 2.6em !important;
}

.form-item.search .value:before {
  width: 20px;
  height: 20px;
  background-size: auto 100%;
  -webkit-mask-position: 40.68% 0;
  position: absolute;
  background-color: #9e9e9e;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
}

.form-item.col {
  flex-direction: column;
}

.form-item.col .field {
  width: 100%;
}

.form-item.col .value {
  width: 100%;
}

.form-item.jcfe {
  justify-content: flex-end;
}

.form-item.btn-area {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
}

.form-item.btn-area.sp {
  justify-content: space-between;
}

.form-item.btn-area.center {
  justify-content: center;
}

@media (min-width: 1180px) {
  .form-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.form-container.inline {
  display: flex;
  align-items: flex-start;
}

.form-container.inline .form-item {
  flex: 1;
}

.form-container.inline .form-item.btn-area {
  max-width: max-content;
  flex: auto;
  margin-top: 0;
}

.form-container.inline .form-item .btn {
  min-width: auto;
}

.form-container.inline .form-item .field {
  width: 50px;
  flex-shrink: 0;
}

.form-container.inline .form-item .value {
  width: calc(100% - 50px);
}

.form-container+hr {
  margin-top: 0;
}

@media (min-width: 740px) {
  .form-container.scale-to-normall {
    width: 90%;
    max-width: 720px;
    max-width: 630px;
    margin: 0 auto;
  }
}

@media (min-width: 1180px) {
  .form-container.scale-to-normall {
    max-width: 990px;
    margin: 0 auto;
  }
}

@media (min-width: 1700px) {
  .form-container.scale-to-normall {
    max-width: 1110px;
  }
}

.form-container.for-12-1 {
  padding-left: 24px;
}

@media (min-width: 740px) and (max-width: 1179px) {
  .form-container.for-12-1 {
    display: flex;
  }
}

@media (min-width: 1380px) {
  .form-container.for-12-1 {
    padding-left: 30px;
  }
}

.form-container.for-12-1 .filename {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  height: 1.25em;
  line-height: 1.25em;
  -webkit-box-orient: vertical;
}

.form-container.for-12-1 .line {
  display: block;
  width: 1px;
  height: 32px;
  border-left: 1px solid #E0E0E0;
  margin: 0 10px;
}

.form-container.for-12-1 .extra-filed {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.form-container.for-12-1 .extra-filed .icon {
  margin-left: 8px;
  cursor: pointer;
}

.form-container.for-12-1 .form-item.three {
  width: 100%;
}

@media (min-width: 740px) and (max-width: 1179px) {
  .form-container.for-12-1 .form-item.three {
    width: max-content;
    min-width: initial;
  }
}

@media (min-width: 1180px) {
  .form-container.for-12-1 .form-item.three {
    width: calc(33.33% - 75px);
  }
}

.block-area {
  border: 1px solid #E0E0E0;
  width: calc(100% - 30px);
  margin: 0 auto 4.5vw;
}

@media (min-width: 1700px) {
  .block-area {
    margin-bottom: 3vw;
  }
}

.block-area .row.field {
  background-color: #eee;
  display: flex;
  justify-content: space-between;
  padding: 8px 15px;
  min-height: 48px;
}

@media (min-width: 1380px) {
  .block-area .row.field {
    padding: 12px 20px;
  }
}

@media (min-width: 1700px) {
  .block-area .row.field {
    padding: 12px 25px;
    min-height: 54px;
  }
}

.block-area .row.field .left {
  font-size: 18px;
  font-weight: 500;
}

.block-area .row.field .left span+span {
  padding-left: 2em;
}

.block-area .form-item {
  padding: 0 40px;
  margin: 0;
}

.block-area .form-item .field {
  border-bottom: 1px solid #BDBDBD;
  padding: 1em 0;
}

.block-area .form-item .value {
  justify-content: flex-start;
  padding-top: 1em;
}

.block-area .form-item .value>div {
  width: 20%;
  flex-shrink: 0;
  margin: 0 0 1em 0;
}

.remark-block>div {
  border: 1px solid #E0E0E0;
  border-radius: 15px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.remark-block textarea {
  width: 100%;
  border: none;
  border-radius: 0;
  margin: 0;
  bottom: 0;
  box-shadow: inset 0 -2px 4px 0px #eee;
}

.remark-block .btn-area {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #FAFAFA;
  padding: 12px 30px;
  border-top: 1px solid #E0E0E0;
}

.btn-area.jcsb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scroll-area {
  max-height: 60vh;
  min-height: 400px;
  overflow-y: auto;
}

.msg-record {
  height: 252px;
  border: 1px solid #BDBDBD;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
  background-color: #fff;
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  color: #757575;
  border-radius: 15px;
  width: 100%;
  overflow-y: auto;
  margin-right: 0 !important;
  padding: 15px;
}

.msg-record::-webkit-scrollbar {
  background-color: transparent;
}

.msg-record::-webkit-scrollbar-track {
  margin: 4px;
}

.msg-record ul {
  display: block;
  padding-bottom: 1em;
}

.msg-record li {
  display: block;
  padding-bottom: 0.5em;
  color: #424242;
}

.msg-record li.orange {
  color: #F37402 !important;
}

.msg-record .date {
  font-size: 0.875rem;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  color: #616161;
}

.msg-record .date:before,
.msg-record .date:after {
  content: "";
  display: block;
  height: 1px;
  border-top: 1px solid #9e9e9e;
  width: 20%;
}

.msg-record .date:before {
  margin-right: 1.5em;
}

.msg-record .date:after {
  margin-left: 1.5em;
}

.msg-record .orange li {
  color: #F37402;
}

.msg-record .orange .date {
  color: #F37402;
}

.msg-record .orange .date:before,
.msg-record .orange .date:after {
  border-color: #F37402;
}

.modal-container {
  background-color: #FAFAFA;
  border-radius: 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding: 3.33vw 4.3vw;
  width: clamp(500px, 50%, 680px);
  transition: all 0.3s ease;
  opacity: 0;
  transform-style: preserve-3d;
  transform: translateY(-80px) perspective(1000px) rotateX(30deg);
}

@media (min-width: 1380px) {
  .modal-container {
    padding: 48px 4.3vw;
  }
}

.modal-container .icon-holder {
  width: 20%;
  max-width: 68px;
  position: relative;
  margin: 0 auto;
}

.modal-container .icon-holder:before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 100%;
}

.modal-container .icon-holder .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.modal-container .icon-holder .icon:before {
  width: 100%;
  height: 100%;
  background-color: #62B8EE;
}

.modal-container .text-holder {
  padding: 1em 0 2.75em;
  text-align: center;
}

.modal-container .text-holder strong {
  font-weight: 700;
}

.modal-container .text-holder .fz-B {
  padding-bottom: 0;
  margin-bottom: 0;
}

.modal-container .progress-bar {
  height: 12px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  background-color: #E0E0E0;
  margin-top: 3em;
  margin-bottom: 0.5em;
}

.modal-container .progress-bar .thumb {
  position: absolute;
  height: 100%;
  width: 0;
  border-radius: 8px;
  background-color: #62B8EE;
  left: 0;
  top: 0;
}

.modal-header {
  position: relative;
}

.modal-header .right {
  position: absolute;
  color: #616161;
  right: 0;
  top: 0;
  text-align: right;
  padding-right: clamp(100px, 11vw, 160px);
}

.has-modal {
  overflow: hidden;
}

.modal {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(29, 67, 111, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  transition-property: opacity;
  overflow-y: auto;
}

.modal .close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  transform: translate(4.3vw, -3.33vw);
}

@media (min-width: 1380px) {
  .modal .close {
    transform: translate(4.3vw, -48px);
  }
}

.modal .close .icon {
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.modal .close .icon:before {
  background-color: #BDBDBD;
  width: 100%;
  height: 100%;
}

@media (hover: hover) {
  .modal .close .icon:before {
    transition: all 0.3s ease;
    transition-property: background-color, transform;
  }

  .modal .close:hover .icon:before {
    background-color: #1D436F;
    transform: scale(1.125);
  }
}

.modal.small.active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.modal.small.active .modal-container {
  transform: none;
  opacity: 1;
  transition: all 0.5s ease;
}

.modal.small .form-item {
  margin: 0 0 20px;
}

.modal.small .form-item .value>div {
  margin-bottom: 10px;
}

.modal.large {
  padding-bottom: 10vh;
}

.modal.large .modal-container {
  width: clamp(880px, 70%, 1280px);
  margin-top: 10vh;
  margin-bottom: auto;
}

.modal.active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.modal.active .modal-container {
  transform: none;
  opacity: 1;
  transition: all 0.5s ease;
}

.modal.layer-2 {
  z-index: 40;
}

.modal.layer-3 {
  z-index: 60;
}

.modal.layer-4 {
  z-index: 80;
}

.modal.layer-5 {
  z-index: 80;
}

.modal.has-stamp .modal-header {
  padding-top: 2vw;
  padding-bottom: 10px;
}

.modal .btn-holder {
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
}

.modal .btn-holder>button {
  flex: 1;
  width: calc(50% - 16px);
  max-width: calc(50% - 16px);
  min-width: auto;
}

.modal .btn-holder>button+button {
  margin-left: 16px;
}

.modal .btn-holder .sp {
  justify-content: space-between;
}

.modal .btn-holder.center {
  justify-content: center;
}

.title-stamp,
.modal-stamp {
  position: absolute;
  width: 60px;
  height: 60px;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid #F37402;
  border-radius: 50%;
  padding: 4px;
  color: #F37402;
  right: 105px;
  top: 40px;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.title-stamp .circle,
.modal-stamp .circle {
  border: 4px solid #F37402;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: rotate(9deg);
  white-space: nowrap;
}

.title-stamp.small,
.modal-stamp.small {
  font-size: 11px;
  letter-spacing: -0.5px;
}

@media (min-width: 1180px) {

  .title-stamp,
  .modal-stamp {
    font-size: 18px;
  }

  .title-stamp.small,
  .modal-stamp.small {
    font-size: 13px;
    letter-spacing: -0.5px;
  }
}

@media (min-width: 1380px) {

  .title-stamp,
  .modal-stamp {
    font-size: clamp(24px, 1.66vw, 30px);
    width: 5.5vw;
    height: 5.5vw;
    max-width: 96px;
    max-height: 96px;
  }

  .title-stamp.small,
  .modal-stamp.small {
    font-size: clamp(16px, 1.3vw, 22px);
    letter-spacing: -0.5px;
  }
}

.has-layer-2 .layer-2 {
  background-color: transparent;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

.has-layer-2 .layer-1 .modal-container {
  opacity: 0;
  transform-style: preserve-3d;
  transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.has-layer-2.has-layer-3 .layer-3 {
  background-color: transparent;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

.has-layer-2.has-layer-3 .layer-1 .modal-container,
.has-layer-2.has-layer-3 .layer-2 .modal-container {
  opacity: 0;
  transform-style: preserve-3d;
  transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.has-layer-2.has-layer-3.has-layer-4 .layer-4 {
  background-color: transparent;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

.has-layer-2.has-layer-3.has-layer-4 .layer-1 .modal-container,
.has-layer-2.has-layer-3.has-layer-4 .layer-2 .modal-container,
.has-layer-2.has-layer-3.has-layer-4 .layer-3 .modal-container {
  opacity: 0;
  transform-style: preserve-3d;
  transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-5 {
  background-color: transparent;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-1 .modal-container,
.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-2 .modal-container,
.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-3 .modal-container,
.has-layer-2.has-layer-3.has-layer-4.has-layer-5 .layer-4 .modal-container {
  opacity: 0;
  transform-style: preserve-3d;
  transform: translateY(80px) perspective(1000px) rotateX(-30deg);
}

.list-table {
  display: block;
  padding: 0;
  border: 1px solid #E0E0E0;
  color: #616161;
  position: relative;
}

.list-table.nested {
  width: 100%;
  border: none;
}

.list-table .lt-row {
  display: flex;
  border-bottom: 1px solid #E0E0E0;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.list-table .lt-row>form {
  display: flex;
  width: 100%;
}

.list-table .lt-row .remark {
  color: #9e9e9e;
  font-size: 12px;
  margin-left: 0.5em;
  font-weight: normal;
  letter-spacing: 0;
}

@media (min-width: 1700px) {
  .list-table .lt-row .remark {
    font-size: 13px;
  }
}

.list-table .lt-row.border:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #E0E0E0;
}

.list-table .lt-row.ovv {
  overflow: visible;
}

.list-table .lt-row.sticky-head {
  position: sticky !important;
  top: 0;
  z-index: 9;
}

.list-table .lt-row.sticky-row {
  position: sticky;
  top: 48px;
  z-index: 9;
  background-color: #FAFAFA;
}

@media (min-width: 1700px) {
  .list-table .lt-row.sticky-row {
    top: 56px;
  }
}

.list-table .lt-row.highlight {
  border: 1px solid #62B8EE;
  border-bottom: 1px solid #E0E0E0;
}

.list-table .lt-row.highlight+.lt-row {
  border: 1px solid #62B8EE;
  border-top: none;
  box-shadow: rgba(98, 184, 238, 0.3) 0px 3px 8px;
}

@media (hover: hover) {
  .list-table .lt-row {
    transition: background-color 0.3s ease;
  }

  .list-table .lt-row:hover {
    background-color: #f6f6f6;
  }
}

@media (hover: hover) {
  .list-table .lt-row.no-hover:hover {
    background-color: transparent;
  }
}

.list-table .lt-row.for-modal-4 .center-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-table .lt-row.for-modal-4 .center-item .btn {
  margin-top: 10px;
  width: 100%;
}

.list-table .lt-row.aifs {
  align-items: flex-start;
}

.list-table .lt-row.ovv {
  overflow: visible;
}

.list-table .lt-row.ovv>div {
  overflow: visible;
}

.list-table .lt-row.hide {
  display: none;
}

.list-table .lt-row:last-of-type {
  border: none;
}

.list-table .lt-row .alert {
  position: relative;
}

.list-table .lt-row .alert:before {
  background-size: auto 100%;
  -webkit-mask-position: 69.49% 0;
  width: 18px;
  height: 18px;
  background-color: #F37402;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(125%, -40%);
}

.list-table .lt-row .alert.icon {
  padding-left: 3px;
}

.list-table .lt-row .alert.icon:before {
  background-size: auto 100%;
  position: relative;
  transform: translate(75%, -10%);
}

.list-table .lt-row .alert.icon22:before {
  -webkit-mask-position: 37.29% 0;
}

.list-table .lt-row .alert.icon41:before {
  -webkit-mask-position: 69.49% 0;
}

@media (min-width: 1700px) {
  .list-table .lt-row .alert:before {
    width: 20px;
    height: 20px;
  }
}

.list-table .lt-row .alert.not-paid:before {
  background-size: auto 100%;
  -webkit-mask-position: 37.29% 0;
}

.list-table .lt-row .multi-form {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border: 1px solid #BDBDBD;
}

.list-table .lt-row .multi-form .line {
  width: 1px;
  display: inline-flex;
  align-items: center;
  height: 28px;
  margin: 0 3px;
}

.list-table .lt-row .multi-form .line:before {
  content: "";
  display: block;
  width: 1px;
  height: 80%;
  border-left: 1px solid #E0E0E0;
}

.list-table .lt-row .multi-form input,
.list-table .lt-row .multi-form select {
  margin: 0;
  border: none;
  flex: 1;
  height: 30px;
}

@media (min-width: 1700px) {

  .list-table .lt-row .multi-form input,
  .list-table .lt-row .multi-form select {
    height: 34px;
  }
}

.list-table .lt-row .multi-form.disabled {
  background-color: rgba(224, 224, 224, 0.6);
}

.list-table .lt-row .multi-form.disabled input,
.list-table .lt-row .multi-form.disabled select {
  background-color: transparent;
}

.list-table .lt-row .multi-form.disabled .line:before {
  border-color: #ccc;
}

.list-table .lt-row .multi-form.error {
  border-color: #F37402;
  border-width: 2px;
}

.list-table .lt-row>div {
  flex-shrink: 0;
  padding: 8px 4px;
  line-height: 1.5em;
  font-size: 14px;
  min-height: 48px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  flex-shrink: 0;
}

.list-table .lt-row>div.must:before {
  content: "✽";
  display: inline-block;
  color: #F37402;
}

.list-table .lt-row>div.center {
  justify-content: center;
  text-align: center;
}

.list-table .lt-row>div.right {
  justify-content: flex-end;
  text-align: right;
}

.list-table .lt-row>div.right input {
  text-align: right;
}

.list-table .lt-row>div.disabled {
  color: #BDBDBD;
}

.list-table .lt-row>div.pdl0 {
  padding-left: 0 !important;
}

.list-table .lt-row>div.for-hr .help-word {
  color: #F37402;
  width: 100%;
  position: absolute;
  right: 12px;
  bottom: -2px;
}

.list-table .lt-row>div.for-hr .help-word.right {
  text-align: right;
  font-size: 12px;
}

.list-table .lt-row>div.for-hr.show-help {
  position: relative;
  overflow: visible;
}

.list-table .lt-row>div.for-hr.show-help input {
  border-color: #F37402;
  transform: translateY(-8px);
}

.list-table .lt-row>div.for-hr.show-help .help-word {
  visibility: visible;
}

@media (min-width: 1380px) {
  .list-table .lt-row>div {
    padding: 12px 8px;
  }
}

@media (min-width: 1700px) {
  .list-table .lt-row>div {
    padding: 12px 25px;
    padding: 12px 12px;
    font-size: 15px;
    min-height: 54px;
  }
}

.list-table .lt-row>div:first-of-type {
  padding-left: 10px;
}

@media (min-width: 1380px) {
  .list-table .lt-row>div:first-of-type {
    padding-left: 12px;
  }
}

@media (min-width: 1700px) {
  .list-table .lt-row>div:first-of-type {
    padding-left: 16px;
  }
}

.list-table .lt-row>div:last-of-type {
  padding-right: 10px;
}

@media (min-width: 1380px) {
  .list-table .lt-row>div:last-of-type {
    padding-right: 12px;
  }
}

@media (min-width: 1700px) {
  .list-table .lt-row>div:last-of-type {
    padding-right: 16px;
  }
}

.list-table .lt-row>div.fn {
  padding-top: 0;
  padding-bottom: 0;
}

.list-table .lt-row>div.min-3-btn {
  min-width: 120px;
}

@media (min-width: 1180px) {
  .list-table .lt-row>div.min-3-btn {
    min-width: 140px;
  }
}

@media (min-width: 1380px) {
  .list-table .lt-row>div.min-3-btn {
    min-width: 160px;
  }
}

.list-table .lt-row>div.jcfe {
  justify-content: flex-end;
}

.list-table .lt-row>div.error {
  overflow: visible;
}

.list-table .lt-row>div.error input {
  border-color: #F37402;
}

.list-table .lt-row>div.show-help {
  position: relative;
  overflow: visible !important;
}

.list-table .lt-row>div.show-help .help-word {
  color: #F37402;
  font-size: 0.75rem;
  position: absolute;
  right: 0;
  bottom: -10px;
  white-space: nowrap;
  line-height: 1.125em;
}

@media (min-width: 1380px) {
    .list-table .lt-row>div.show-help .help-word {
    bottom: -5px;
    right: 5px;
  }
}

@media (min-width: 1700px) {
  .list-table .lt-row>div.show-help .help-word {
    right: 10px;
  }
}


.list-table .lt-row.no-border {
  border-bottom-color: transparent;
}

.list-table .lt-row.no-border>div {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

@media (min-width: 1380px) {
  .list-table .lt-row.no-border>div {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}

@media (min-width: 1700px) {
  .list-table .lt-row.no-border>div {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}

@media (min-width: 1380px) {
  .list-table .lt-row.intensive {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: 1380px) {
  .list-table .lt-row.intensive.thin {
    font-weight: 400;
  }
}

.list-table .lt-row.intensive>div {
  flex-shrink: 0;
  padding: 8px 4px;
  line-height: 1.5em;
  font-size: 14px;
  min-height: 48px;
  display: flex;
  align-items: center;
}

@media (min-width: 1380px) {
  .list-table .lt-row.intensive>div {
    padding: 12px 4px;
  }
}

@media (min-width: 1700px) {
  .list-table .lt-row.intensive>div {
    padding: 12px 6px;
  }
}

.list-table .lt-row.lt-th {
  background-color: #eee;
  color: #424242;
  font-weight: 500;
}

.list-table .lt-row .text-right {
  text-align: right;
  justify-content: flex-end;
}

.list-table .lt-row .text-right input {
  text-align: right;
}

.list-table .lt-row .text-center {
  text-align: center;
  justify-content: center;
}

.list-table .lt-row .text-center input {
  text-align: center;
}

.list-table.database-style {
  border: none;
}

.list-table.database-style .lt-row {
  border: none;
  border-radius: 8px;
}

.list-table.database-style .lt-row:nth-of-type(2n + 2) {
  background-color: #eee;
}

.list-table.database-style .lt-row input {
  border-radius: 10px;
}

.list-table.database-style .lt-row.lt-th {
  color: #4e8aae;
  background-color: transparent;
}

@media (min-width: 1380px) {
  .list-table.database-style .lt-row.lt-th>div {
    font-size: 1em;
  }
}

@media (min-width: 1700px) {
  .list-table.database-style .lt-row.lt-th>div {
    font-size: 1.125em;
  }
}

.list-table.transparent {
  border: none;
}

.list-table.transparent .lt-row {
  border: none;
}

.list-table.transparent .lt-row>div {
  padding: 6px 3px;
}

@media (min-width: 1380px) {
  .list-table.transparent .lt-row>div {
    padding: 8px 5px;
  }
}

.list-table.transparent .lt-row>div .btn {
  height: 32px;
}

.list-table.transparent .lt-row>div.has-nested>div {
  padding-left: 3px;
  padding-right: 3px;
}

@media (min-width: 1380px) {
  .list-table.transparent .lt-row>div.has-nested>div {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.list-table.transparent .lt-row.lt-th {
  background-color: transparent;
}

.list-table.transparent .lt-row.for-formula {
  background-color: transparent;
}

.list-table.transparent .lt-row.for-formula>div {
  padding: 6px 3px 0;
}

@media (min-width: 1380px) {
  .list-table.transparent .lt-row.for-formula>div {
    padding: 8px 5px 0;
  }
}

.list-table.transparent .lt-row.for-formula>div.no-form {
  padding-bottom: 22px;
}

@media (hover: hover) {
  .list-table.transparent .lt-row.for-formula {
    background-color: transparent;
  }

  .list-table.transparent .lt-row.for-formula:hover {
    background-color: transparent;
  }
}

.list-table.transparent.formula-group:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #E0E0E0;
  margin-top: 25px;
}

.list-table.transparent.formula-group+.formula-group {
  padding-top: 60px;
}

@media (min-width: 1700px) {
  .list-table.transparent.formula-group+.formula-group {
    padding-top: 80px;
  }
}

.list-table.for-c11 .lt-row>div:first-of-type {
  width: 13.5vw;
  flex: initial;
  flex-shrink: 0;
}

.list-table.for-c11.nested .lt-row>div:first-of-type {
  width: calc(13.5vw - 1px);
}

.list-table .btn+.btn {
  margin-left: 0.5em;
}

@media (min-width: 1700px) {
  .list-table .btn+.btn {
    margin-left: 1em;
  }
}

.list-table .fn-bar {
  display: flex;
  align-items: center;
  padding: 0;
}

.list-table .fn-bar .btn+.btn {
  margin-left: 0.1em;
}

@media (min-width: 1700px) {
  .list-table .fn-bar .btn+.btn {
    margin-left: 0.25em;
  }
}

.list-table .min-100 {
  min-width: 120px;
}

@media (min-width: 1700px) {
  .list-table .min-100 {
    min-width: 130px;
  }
}

.list-table .min-120 {
  min-width: 120px;
}

@media (min-width: 1700px) {
  .list-table .min-120 {
    min-width: 140px;
  }
}

.list-table .min-130 {
  min-width: 130px;
}

@media (min-width: 1700px) {
  .list-table .min-130 {
    min-width: 140px;
  }
}

.list-table .max-60 {
  max-width: 60px;
  text-align: center;
  justify-content: center;
}

.list-table .max-100 {
  max-width: 100px;
  min-width: 100px;
}

@media (min-width: 1700px) {
  .list-table .max-100 {
    max-width: 140px;
  }
}

.list-table .flex-1 {
  flex: 1;
}

.list-table .flex-2 {
  flex: 2;
}

.list-table .flex-3 {
  flex: 3;
}

.list-table .flex-4 {
  flex: 4;
}

.list-table .flex-5 {
  flex: 5;
}

.list-table .flex-6 {
  flex: 6;
}

.list-table .flex-7 {
  flex: 7;
}

.list-table .flex-8 {
  flex: 8;
}

.list-table .flex-auto {
  flex: auto;
}

.list-table .flex-space {
  width: 10px;
  flex-shrink: 0;
}

@media (min-width: 1700px) {
  .list-table .flex-space {
    width: 20px;
  }
}

.list-table .max-14vw {
  max-width: 14vw;
}

.list-table .balance-3 {
  padding-left: 16px !important;
}

@media (min-width: 1380px) {
  .list-table .balance-3 {
    padding-left: 52px !important;
  }
}

@media (min-width: 1700px) {
  .list-table .balance-3 {
    padding-left: 80px !important;
  }
}

.list-table .hover-fn {
  max-width: max-content;
  margin: 0;
  transition: all 0.35s ease;
  transition-property: transform, opacity;
  padding: 0 5px 0 0 !important;
  display: flex;
  align-items: center;
}

@media (min-width: 740px) {
  .list-table .hover-fn {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateX(20px);
    padding: 0;
    height: 100%;
  }
}

.list-table .hover-hide {
  transition: opacity 0.3s ease;
}

.list-table .show-hovers .hover-fn {
  pointer-events: auto;
  opacity: 1;
  transform: none;
}

.list-table .show-hovers .hover-hide {
  opacity: 0;
  pointer-events: none;
}

.list-table .max1 {
  max-width: 60px;
  max-width: 55px;
}

@media (min-width: 1380px) {
  .list-table .max1 {
    max-width: 80px;
    max-width: 70px;
  }
}

.list-table .ems {
  max-width: 30px;
  padding: 0 !important;
}

.list-table .ems .icon {
  width: 30px;
  height: 30px;
}

.list-table .ems .icon:before {
  width: 30px;
  height: 30px;
}

@media (min-width: 1380px) {
  .list-table .ems {
    max-width: 35px;
  }

  .list-table .ems .icon {
    width: 35px;
    height: 35px;
  }

  .list-table .ems .icon:before {
    width: 35px;
    height: 35px;
  }
}

.list-table input,
.list-table select {
  font-size: 14px;
}

.list-table .form-item.no-field input,
.list-table input {
  border-radius: 0;
  padding-left: 5px !important;
  padding-right: 4px;
}

@media (min-width: 1700px) {

  .list-table .form-item.no-field input,
  .list-table input {
    padding-left: 7px !important;
    padding-right: 5px;
  }
}

.list-table select {
  background: #fff url("../images/layout/arrow-select.svg") no-repeat right 8px center;
  background-size: 10px auto;
  border-radius: 0;
  padding-left: 5px;
}

.list-table select.no-border {
  padding-left: 0;
}

.list-table select:disabled {
  background-color: rgba(224, 224, 224, 0.6);
  color: #888;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: not-allowed;
  opacity: 1;
}

@media (min-width: 1700px) {
  .list-table select {
    padding-left: 7px;
    background: #fff url("../images/layout/arrow-select.svg") no-repeat right 12px center;
    background-size: 12px auto;
  }

  .list-table select.no-border {
    padding-left: 0;
  }

  .list-table select:disabled {
    background-color: rgba(224, 224, 224, 0.6);
    color: #888;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    cursor: not-allowed;
    opacity: 1;
  }
}

.list-table a {
    color: #62B8EE;
    text-decoration: underline;
    transition: color 0.15s ease;
    display: block;
    isolation: isolate;
    position: relative;
    cursor: pointer;
}

.list-table a.btn {
  display: inline-flex;
  text-decoration: none;
}

@media (hover: hover) {
  .list-table a:hover {
    color: #1D436F;
    border-color: #1D436F;
  }
}

.list-table.no-border {
  border: none;
}

.list-table .fn.jcfe {
  justify-content: flex-end;
}

.scroll-wrapper {
  width: 100%;
  position: relative;
  border: 1px solid #E0E0E0;
}

.scroll-wrapper .list-table-wrapper {
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.scroll-wrapper .list-table {
  border: none;
}

.scroll-wrapper .list-table .lt-row {
  position: static;
}

.scroll-wrapper .lt-row {
  height: 57px;
  border: none;
}

.scroll-wrapper .lt-row.lt-th {
  height: 48px;
}

.scroll-wrapper .lt-row.lt-th [class*=" fixed-"] {
  background-color: transparent;
  height: 46px;
  box-sizing: border-box;
}

.scroll-wrapper .lt-row.lt-th [class*=" fixed-"]:after {
  content: "";
  display: block;
  width: 100%;
  height: calc(100% - 1px);
  top: 0;
  background-color: #eee;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

@media (min-width: 1700px) {
  .scroll-wrapper .lt-row.lt-th [class*=" fixed-"]:after {
    top: 3px;
  }
}

.line-inline {
  display: inline-block;
  width: 1px;
  height: 16px;
  border-left: 1px solid #BDBDBD;
  margin: 0 5px;
  vertical-align: middle;
}

@media (min-width: 1380px) {
  .modal .list-table .lt-row>div {
    padding: 12px 4px;
  }
}

@media (min-width: 1700px) {
  .modal .list-table .lt-row>div {
    padding: 12px 6px;
  }
}

.modal .list-table .lt-row>div:first-of-type {
  padding-left: 10px;
}

@media (min-width: 1380px) {
  .modal .list-table .lt-row>div:first-of-type {
    padding-left: 10px;
  }
}

@media (min-width: 1700px) {
  .modal .list-table .lt-row>div:first-of-type {
    padding-left: 12px;
  }
}

.modal .list-table .lt-row>div:last-of-type {
  padding-right: 10px;
}

@media (min-width: 1380px) {
  .modal .list-table .lt-row>div:last-of-type {
    padding-right: 10px;
  }
}

@media (min-width: 1700px) {
  .modal .list-table .lt-row>div:last-of-type {
    padding-right: 12px;
  }
}

.value .list-table+.btn-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  width: 100%;
  margin: 0 !important;
}

.tip {
  position: relative;
  cursor: help;
}

.tip>p {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  height: 1.5em;
  line-height: 1.5em;
  -webkit-box-orient: vertical;
}

.tip:before {
  content: attr(data-tip);
  display: block;
  width: 220px;
  position: absolute;
  background-color: #fff;
  border: 1px solid #62B8EE;
  padding: 10px 15px;
  font-size: 13px;
  line-height: 1.5em;
  pointer-events: none;
  opacity: 0;
  transform-origin: center bottom;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -55px);
  border-radius: 8px;
  color: #666;
  max-height: 200px;
  overflow: hidden;
  transition: all 0.2s ease;
  transition-property: opacity, transform;
}

.tip:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -55px);
  opacity: 0;
  border-width: 5px 8px 0 8px;
  border-color: #62B8EE transparent transparent transparent;
  transition: all 0.2s ease;
  transition-property: opacity, transform;
  position: absolute;
}

.tip:hover:before {
  opacity: 1;
  transform: translate(-50%, -45px);
}

.tip:hover:after {
  opacity: 1;
  transform: translate(-50%, -40px);
}

.info-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em 0.5em;
}

.info-holder.no-padding-left {
  padding-left: 0;
}

.info-holder .item+.item {
  margin-left: 4em;
}

.small-table {
  max-height: 350px;
  overflow-y: auto;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  margin-top: 30px;
}

.mgb-8 {
  margin-bottom: 8px;
}

.w80 {
  max-width: 80%;
}

.fc-orange {
  color: #F37402;
}

.col-table {
  display: block;
  width: 100%;
}

.col-table+.col-table {
  margin-left: 10px;
}

.col-table>li {
  height: 32px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

@media (min-width: 1700px) {
  .col-table>li {
    height: 36px;
  }
}

.col-table>li.space {
  height: 16px;
}

.col-table>li input,
.col-table>li select {
  width: 100%;
}

.col-table>li .form-item.independent {
  margin-bottom: 0;
}

.col-table .flex {
  display: flex;
  width: 100%;
  align-items: center;
}

.col-table .flex>div {
  flex-shrink: 0;
  width: 50%;
}

.col-table .flex .right {
  text-align: right;
}

.col-table .flex .field {
  padding-right: 10px;
}

@media (min-width: 1380px) {
  .col-table .flex .field {
    padding-right: 20px;
  }
}

.modal.small .modal-container {
  width: clamp(360px, 31.25%, 500px);
  padding: 3vw 3.88vw 25px;
  border-radius: 12px;
}

@media (min-width: 1380px) {
  .modal.small .modal-container {
    padding-bottom: 2vw;
  }
}

.modal.small .modal-container .title-holder {
  color: #424242;
  font-size: 18px;
  padding: 1em 0 1.5em;
  font-weight: 500;
  text-align: center;
}

.modal.small .modal-container .content-holder {
  padding-bottom: 30px;
}

@media (min-width: 1700px) {
  .modal.small .modal-container .content-holder {
    padding-bottom: 40px;
  }
}

.modal.small.alert .icon-holder .icon:before {
  background-color: #F37402;
}

html,
body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  image-rendering: -webkit-optimize-contrast;
  background: linear-gradient(to bottom, #014089 0%, #241B6A 100%);
  min-height: 100vh;
  line-height: 1.75;
}

@media (min-width: 0) and (max-width: 739px) {

  html,
  body {
    line-height: 1.75;
  }
}

body {
  font-family: "Noto Sans TC", "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #424242;
}

@media (min-width: 0) and (max-width: 979px) {
  body {
    overflow: hidden;
    max-width: 100vw;
    max-width: 100vh;
  }

  body:before {
    content: "本服務尚不支援手機";
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, #014089 0%, #241B6A 100%);
    font-size: 2rem;
    color: #fff;
    z-index: 999;
    box-sizing: border-box;
  }
}

*,
*:focus,
*:hover {
  outline: none;
}

a {
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
  line-height: 0;
}

.img {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.img:before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 56%;
}

section {
  max-width: 100vw;
}

@media (min-width: 740px) {
  .container {
    width: 90%;
    max-width: 720px;
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (min-width: 980px) {
  .container {
    max-width: 660px;
  }
}

@media (min-width: 1180px) {
  .container {
    max-width: 960px;
    margin: 0 auto;
  }
}

@media (min-width: 1380px) {
  .container {
    max-width: 1080px;
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 980px) {
  .container.narrow {
    max-width: 600px;
    max-width: 660px;
    margin: 0 auto;
  }
}

@media (min-width: 1380px) {
  .container.narrow {
    max-width: 720px;
  }
}

@media (min-width: 1700px) {
  .container.narrow {
    max-width: 840px;
  }
}

@media (min-width: 740px) {
  .container.full {
    max-width: calc(100% - 80px);
  }
}

@media (min-width: 1180px) {
  .container.full {
    max-width: calc(100% - 80px);
  }
}

@media (min-width: 1700px) {
  .container.full {
    max-width: calc(100% - 80px);
  }
}

hr {
  display: block;
  box-shadow: none;
  width: 100%;
  height: 1px;
  border: none;
  border-bottom: 1px solid #BDBDBD;
  margin: 1em auto 2.5em;
}

@media (min-width: 1180px) {
  hr {
    width: calc(100% - 30px);
  }
}

hr.full {
  width: 100%;
  margin: 2em auto;
}

.copyright {
  text-align: center;
  color: #9E9E9E;
  border-top: 4px solid #BDBDBD;
  margin-top: 4vw;
  font-family: "Roboto", sans-serif;
  padding: 1em 0;
  font-size: .875rem;
}

.elips-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  height: 1.5em;
  line-height: 1.5em;
  -webkit-box-orient: vertical;
}

.john {
  background-color: rgba(243, 116, 2, 0.15);
  padding: 30px;
  border-radius: 12px;
  margin: 40px auto;
  width: 100%;
  color: #F37402;
  border: 2px dashed #F37402;
}

.nav-container {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 3em;
}

.nav-container .item {
  position: relative;
  border-radius: 20px 0 0 20px;
}

.nav-container .item a {
  display: flex;
  align-items: center;
  padding: 0.7em 20px;
}

.nav-container .item .icon {
  margin-right: 0.5rem;
}

.nav-container .item .icon:before {
  background-color: #fff;
  transition: none;
}

@media (min-width: 1700px) {
  .nav-container .item .icon:before {
    width: 32px;
    height: 32px;
  }
}

.nav-container .item span {
  color: #fff;
  transition: background-color .3s ease;
  line-height: 1.25em;
}

@media (min-width: 1700px) {
  .nav-container .item a {
    padding: 0.85em 20px;
  }

  .nav-container .item a br {
    display: none;
  }
}

@media (hover: hover) {
  .nav-container .item:hover {
    background-color: #FAFAFA;
  }

  .nav-container .item:hover .icon:before {
    background-color: #F37402;
  }

  .nav-container .item:hover span {
    color: #F37402;
  }
}

.nav-container .item.active {
  background-color: #FAFAFA;
}

.nav-container .item.active .icon:before {
  background-color: #F37402;
}

.nav-container .item.active span {
  color: #F37402;
}

.nav-container .dropmenu {
  background-color: #FAFAFA;
  color: #616161;
  border-radius: 0 20px 20px 0;
  box-shadow: rgba(149, 157, 165, 0.2) 3px 8px 24px;
  left: 100%;
  top: 0;
  opacity: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 3;
  max-height: 0;
  transition: none;
}

.nav-container .dropmenu a {
  color: #616161;
  display: block;
  white-space: nowrap;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  position: relative;
  min-width: 8em;
  text-align: right;
}

.nav-container .dropmenu a:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #E0E0E0;
  position: absolute;
  width: calc(100% - 40px);
  left: 20px;
  bottom: 0;
}

.nav-container .dropmenu a:last-of-type:after {
  display: none;
}

.nav-container .dropmenu a:active,
.nav-container .dropmenu a:hover {
  color: #F37402;
}

.nav-container .has-dropmenu {
  position: relative;
  overflow: hidden;
}

.nav-container .has-dropmenu.active .dropmenu {
  pointer-events: auto;
  transform: none;
  opacity: 1;
  max-height: 400px;
  transition: max-height .8s ease;
}

.header {
  height: 72px;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: #014089;
}

@media (min-width: 740px) {
  .header {
    padding: 0 40px 0 56px;
  }
}

.header a {
  color: #fff;
}

.header .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
}

@media (min-width: 1700px) {
  .header .logo {
    width: 190px;
  }
}

.header .logo .icon:before {
  width: 58px;
  height: 58px;
  background-color: #fff;
}

.header .time {
  padding-left: 2vw;
}

.header .fn-bar {
  width: calc(100% - 160px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 1700px) {
  .header .fn-bar {
    width: calc(100% - 190px);
  }
}

.header .fn-bar a {
  display: inline-flex;
  align-items: center;
}

.header .fn-bar a+a {
  margin-left: 26px;
}

@media (hover: hover) {
  .header .fn-bar a {
    transition: color 0.15s ease;
  }

  .header .fn-bar a:hover {
    color: #62B8EE;
  }

  .header .fn-bar a:hover .icon:before {
    background-color: #62B8EE;
  }

  .header .fn-bar a.logout:hover {
    color: #F37402;
  }

  .header .fn-bar a.logout:hover .icon:before {
    background-color: #F37402;
  }
}

.header .fn-bar a.notice {
  position: relative;
}

.header .fn-bar a.notice:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: #F37402;
  position: absolute;
  right: 8px;
  top: 4px;
  border-radius: 50%;
  z-index: 3;
  animation: notice ease 1.5s infinite forwards;
}

.header .fn-bar .icon {
  margin-right: 5px;
}

.header .fn-bar .icon:before {
  width: 36px;
  height: 36px;
  background-color: #fff;
}

@keyframes notice {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(1.1);
  }
}

.nav {
  width: 160px;
  height: calc(100vh - 72px - 2vw);
  overflow-y: auto;
  margin-top: 2vw;
}

.nav::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #19135a;
  border-radius: 5px;
  box-sizing: border-box;
}

.nav::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  border-radius: 5px;
  margin-right: 20px;
}

.nav::-webkit-scrollbar-thumb {
  background-color: #327ee8;
  border-radius: 5px;
  border: none;
}

@media (min-width: 1700px) {
  .nav {
    width: 190px;
  }
}

@media (hover: hover) {

  .nav::-webkit-scrollbar-track,
  .nav::-webkit-scrollbar,
  .nav::-webkit-scrollbar-thumb {
    opacity: 0;
    visibility: hidden;
  }

  .nav:hover::-webkit-scrollbar-track,
  .nav:hover::-webkit-scrollbar,
  .nav:hover::-webkit-scrollbar-thumb {
    opacity: 1;
    visibility: visible;
  }
}

.main {
  width: 100%;
}

@media (min-width: 740px) {
  .main {
    padding: 0 40px 0 56px;
    display: flex;
  }
}

.page-content {
  padding-top: 30px;
  background-color: #FAFAFA;
  width: calc(100% - 160px);
  height: calc(100vh - 72px);
  padding: 0;
  display: block;
  overflow-y: auto;
}

@media (min-width: 740px) {
  .page-content {
    border-radius: 2vw 2vw 0 0;
    padding: 24px 24px 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (min-width: 1700px) {
  .page-content {
    width: calc(100% - 190px);
    padding: 48px 48px 0;
    padding: 0;
  }
}

.page-content .title-area .container {
  display: flex;
  align-items: flex-end;
  align-items: center;
  justify-content: space-between;
  padding: 2.5em 0 1.5em;
  position: relative;
}

.page-content .title-area .container .fz-A,
.page-content .title-area .container .fz-B {
  margin-bottom: 8px;
}

@media (min-width: 1380px) {

  .page-content .title-area .container .fz-A,
  .page-content .title-area .container .fz-B {
    margin-bottom: 16px;
  }
}

.page-content .title-area .right {
  padding-bottom: 8px;
}

@media (min-width: 1380px) {
  .page-content .title-area .right {
    padding-bottom: 12px;
  }
}

.page-content .title-area .title-stamp {
  right: 0;
}

.page-content .title-area.has-stamp .right {
  padding-right: clamp(50px, 6vw, 150px);
}

.page-content .title-area.has-stamp.no-padding-right .right {
  padding-right: 0;
}

.page-content .footer {
  width: calc(100% - 50px);
  margin-left: auto;
  margin-right: auto;
}

@media (hover: hover) {

  .page-content::-webkit-scrollbar-track,
  .page-content::-webkit-scrollbar,
  .page-content::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  .page-content::-webkit-scrollbar-track {
    margin-top: 1.8vw;
  }

  .page-content::-webkit-scrollbar {
    background-color: transparent;
  }

  .page-content:hover::-webkit-scrollbar-track,
  .page-content:hover::-webkit-scrollbar,
  .page-content:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

.full {
  width: 100%;
}

.full.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-area {
  padding-bottom: 30px;
}

.review-area .title-stamp {
  right: 0;
  top: -0.2vw;
}

.review-area .r-comment {
  display: flex;
  width: 100%;
}

.review-area .r-comment strong {
  white-space: nowrap;
  padding-right: 20px;
}

.review-area .r-list {
  display: flex;
  width: max-content;
  flex: initial;
  width: 80%;
  max-width: 520px;
  color: #616161;
}

@media (min-width: 1180px) {
  .review-area .r-list {
    width: 55%;
  }
}

@media (min-width: 1380px) {
  .review-area .r-list {
    width: 50%;
  }
}

.review-area .r-list ul {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  width: min-content;
}

@media (min-width: 980px) {
  .review-area .r-list ul {
    width: 100%;
  }
}

.review-area .r-list strong {
  height: 1.5em;
  line-height: 1.5em;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  margin-right: 20px;
}

@media (min-width: 1700px) {
  .review-area .r-list strong {
    font-size: 16px;
  }
}

.review-area .r-list li {
  width: 100%;
  margin: 0 0 0 0;
  padding: 0;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  height: 1.5em;
  line-height: 1.5em;
  -webkit-box-orient: vertical;
  font-size: 14px;
  width: max-content;
}

@media (min-width: 980px) {
  .review-area .r-list li {
    width: 50%;
  }
}

@media (min-width: 1700px) {
  .review-area .r-list li {
    font-size: 16px;
  }
}

.review-area .r-container {
  display: flex;
  width: max-content;
  flex: initial;
  color: #616161;
}

@media (min-width: 1380px) {
  .review-area .r-container {
    padding-top: clamp(8px, 0.7vw, 11px);
  }
}

.review-area .r-container strong {
  height: 1.5em;
  line-height: 1.5em;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  margin-right: 20px;
}

@media (min-width: 1700px) {
  .review-area .r-container strong {
    font-size: 16px;
  }
}

.review-area .r-container ul {
  width: max-content;
  padding-right: 10px;
}

@media (min-width: 1380px) {
  .review-area .r-container ul {
    padding-right: 15px;
  }
}

.review-area .r-container ul:last-of-type {
  padding-right: 5px;
}

.review-area .r-container li {
  margin: 0 0 0 0;
  padding: 0;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  height: 1.5em;
  line-height: 1.5em;
  -webkit-box-orient: vertical;
  font-size: 14px;
  width: max-content;
}

@media (min-width: 1700px) {
  .review-area .r-container li {
    font-size: 15px;
  }
}

.review-area .container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-right: 6.5vw;
  min-height: 7vw;
}

.inline-loading {
  color: #94CAED;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0) translateY(-100%);
  animation-delay: -0.16s;
  display: block;
  margin-left: 8em;
}

.inline-loading,
.inline-loading:before,
.inline-loading:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.inline-loading:before,
.inline-loading:after {
  content: "";
  position: absolute;
  top: 0;
}

.inline-loading:before {
  left: -5em;
  animation-delay: -0.32s;
}

.inline-loading:after {
  left: 5em;
  animation-delay: 0.32s;
}

@keyframes bblFadInOut {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.tooltip-zindex {
  z-index: 9;
  width: 200px !important;
  word-break: break-all;
}

.dropmenu-container {
  position: relative;
  width: 100%;
  margin: 0 !important;
}

.dropmenu-container .dropmenu-value {
  border: 1px solid #BDBDBD;
  font-size: 1rem;
  transition: border-color .3s ease;
  height: 32px;
  box-sizing: border-box;
  margin: 0;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  background: #fff url("../images/layout/arrow-select.svg") no-repeat right 16px center;
  background-size: 15px auto;
  cursor: pointer;
}

@media (min-width: 1700px) {
  .dropmenu-container .dropmenu-value {
    height: 36px;
  }
}

.dropmenu-container .dropmenu-value.focus {
  border-color: #333;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.dropmenu-container .dropmenu-value.disabled {
  background-color: rgba(224, 224, 224, 0.6);
  color: #888;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: not-allowed;
}

@media (min-width: 1700px) {
  .dropmenu-container .dropmenu-value {
    height: 36px;
  }
}

.dropmenu-container .dropmenu {
  position: absolute;
  background-color: #fff;
  width: 100%;
  padding: 5px 0 1em;
  border-radius: 8px;
  transition: transform .2s ease;
  opacity: none;
  visibility: hidden;
  pointer-events: none;
  max-height: 360px;
  overflow-y: auto;
}

.dropmenu-container .dropmenu::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #eee;
  border-radius: 5px;
  border: 3px solid #fff;
  box-sizing: border-box;
}

.dropmenu-container .dropmenu::-webkit-scrollbar {
  width: 8px;
  background-color: #eee;
  border-radius: 5px;
}

.dropmenu-container .dropmenu::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
  border: none;
}

.dropmenu-container .dropmenu li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
}

@media (hover: hover) {
  .dropmenu-container .dropmenu li {
    transition: background-color .1s ease;
  }

  .dropmenu-container .dropmenu li:hover {
    background-color: rgba(189, 189, 189, 0.1);
  }
}

.dropmenu-container .dropmenu [data-value] {
  cursor: pointer;
  flex: auto;
  padding: 0.2em 0;
}

.dropmenu-container .dropmenu .fn .icon {
  pointer-events: none;
}

.dropmenu-container .additional {
  border-top: 1px solid #E0E0E0;
  padding: 0.5em 1em;
  margin-top: 0.5em;
}

.dropmenu-container .additional a {
  color: #62B8EE;
  border-bottom: 1px solid #62B8EE;
}

.dropmenu-container.active {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 8px 8px 0 0;
  z-index: 9;
}

.dropmenu-container.active .dropmenu-value {
  border-top: #fff;
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  border: none;
}

.dropmenu-container.active .dropmenu {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 12px;
  border-radius: 0 0 8px 8px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.login {
  background: none;
  background: #79BDE8 url("../images/layout/bg-login.svg") no-repeat center bottom -20vh;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box {
  max-width: 578px;
  width: 80%;
}

.login-box .title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-bottom: 8vh;
}

.login-box .title .icon {
  margin-right: 15px;
}

.login-box .title .icon:before {
  width: 80px;
  height: 80px;
  background-color: #fff;
}

.login-box .subtitle {
  text-align: center;
}

.login-box .subtitle .fz-B {
  font-weight: 500;
}

.login-box .subtitle .fz-C {
  font-weight: 500;
}

.dashboard-header {
  height: 200px;
  padding: 30px 100px 30px;
}

@media (min-width: 1380px) {
  .dashboard-header {
    height: 250px;
    padding: 70px 100px 32px;
  }
}

.dashboard-header>div {
  color: #fff;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 24px;
}

.dashboard-header .left {
  display: flex;
}

.dashboard-header .left .title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-header .left .title .icon:before {
  width: 90px;
  height: 90px;
  background-color: #fff;
}

.dashboard-header .left .title h1 {
  font-size: 16px;
}

.dashboard-header .welcome {
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.dashboard-header .welcome .fz-A {
  margin-bottom: 0;
  font-weight: 500;
}

.dashboard-header .welcome .fz-B {
  margin-bottom: 0.5em;
}

.dashboard-header .right {
  padding: 20px 34px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.dashboard-header .btn-middle {
  min-width: 128px;
  justify-content: space-between;
  align-items: center;
}

.dashboard-header .btn-middle i {
  display: block;
  width: 10px;
  height: 10px;
  -webkit-mask-image: url("../images/layout/arrow-select.svg");
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  background-color: #fff;
  transition: transform 0.4s ease;
}

@media (hover: hover) {
  .dashboard-header .btn-middle:hover {
    color: #fff;
    background-color: #F37402;
    border-color: #F37402;
  }
}

.dashboard-header .btn-logout {
  display: flex;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.dashboard-header .btn-logout span {
  margin-left: 5px;
  transition: color 0.3s ease;
}

.dashboard-header .btn-logout .icon:before {
  background-color: #fff;
  width: 32px;
  height: 32px;
}

@media (hover: hover) {
  .dashboard-header .btn-logout:hover {
    color: #F37402;
  }

  .dashboard-header .btn-logout:hover .icon:before {
    background-color: #F37402;
  }
}

.dropbuntton {
  position: relative;
}

.dropbuntton .dropmenu {
  position: absolute;
  padding-top: 3px;
  width: 100%;
  transition: all 0.35s ease;
  pointer-events: none;
  opacity: 0;
  transform: translateY(10px);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dropbuntton .dropmenu a {
  color: #fff;
  transition: all 0.3s ease;
  display: block;
  padding: 8px 16px;
  line-height: 1.5em;
  white-space: nowrap;
  background-color: #62B8EE;
  overflow: hidden;
}

.dropbuntton .dropmenu a:first-of-type {
  border-radius: 4px 4px 0 0;
}

.dropbuntton .dropmenu a:last-of-type {
  border-radius: 0 0 4px 4px;
}

@media (hover: hover) {
  .dropbuntton .dropmenu a:hover {
    background-color: #86c4ea;
  }
}

.dropbuntton.active .btn-middle i {
  transform: rotate(180deg);
}

.dropbuntton.active .dropmenu {
  pointer-events: auto;
  opacity: 1;
  transform: none;
}

.dashboard-body {
  background-color: #FAFAFA;
  width: 100%;
  height: calc(100vh - 200px);
  overflow: hidden;
  padding: 0;
}

.dashboard-body .scrollbar {
  overflow-y: auto;
  height: calc(100vh - 200px);
}

@media (min-width: 1380px) {
  .dashboard-body .scrollbar {
    height: calc(100vh - 250px);
  }
}

.dashboard-body.for-user-management {
  height: calc(100vh - 72px);
}

.dashboard-body .container {
  display: flex;
  padding: 40px 58px 0;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
}

.dashboard-body .left {
  width: 29%;
  overflow-y: auto;
  height: 100%;
  padding-right: 40px;
}

.dashboard-body .left .title {
  display: flex;
  align-items: center;
  padding-bottom: 47px;
}

.dashboard-body .left .title .icon:before {
  width: 40px;
  height: 40px;
  background-color: #F37402;
}

.dashboard-body .left .title .fz-B {
  margin-bottom: 0;
  margin-left: 0.5em;
}

.dashboard-body .left .title.disabled .icon:before {
  background-color: #BDBDBD;
}

.dashboard-body .left .title.disabled .fz-B {
  color: #BDBDBD;
}

.dashboard-body .left.has-border {
  border-right: 1px solid #BDBDBD;
  min-height: 621px;
}

.dashboard-body .right {
  width: 71%;
  padding-left: 40px;
}

@media (min-width: 1700px) {
  .dashboard-body .right {
    padding-left: 60px;
  }
}

.dashboard-body .bottom {
  width: 100%;
}

@media (min-width: 740px) {
  .dashboard-body {
    border-radius: 2vw 2vw 0 0;
  }
}

@media (min-width: 1380px) {
  .dashboard-body {
    height: calc(100vh - 250px);
  }
}

@media (min-width: 1700px) {
  .dashboard-body {
    width: 100%;
  }
}

.dashboard-body .for-text {
  display: flex;
  justify-content: center;
  padding-top: 87px;
}

.dashboard-body .for-text p {
  max-width: 60%;
  display: block;
}

.dashboard-body .for-text .btn {
  margin: 4em auto;
  display: block;
}

.dashboard-body .section {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  overflow: hidden;
}

.dashboard-body .section.with-border:before {
  content: "";
  display: block;
  width: 100%;
  margin-bottom: 40px;
  border-top: 1px solid #BDBDBD;
}

.dashboard-body .section.for-admin {
  align-items: flex-start;
  justify-content: space-between;
}

.notice-item {
  display: block;
  color: #424242;
  border-bottom: 1px solid #E0E0E0;
  margin-bottom: 24px;
}

.notice-item .fn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

.notice-item h6 {
  padding-bottom: 1em;
}

.notice-item .date {
  color: #757575;
  font-family: "Roboto", sans-serif;
}

.db-item {
  display: flex;
  flex-direction: column;
  width: calc(50% - 24px);
  margin: 0 12px 16px;
  color: #777;
  border: 1px solid #e5e6e9;
  border-radius: 20px;
  padding: 16px 24px;
  background: linear-gradient(180deg, #f5f5f5 17.71%, #dddddd 88.54%, #cdcdcd 100%);
}

@media (min-width: 980px) {
  .db-item {
    width: calc(33.33% - 24px);
    padding: 1.4vw 1.6vw 1.2vw;
  }
}

@media (min-width: 1380px) {
  .db-item {
    width: calc(25% - 24px);
  }
}

.db-item .icon:before {
  width: 36px;
  height: 36px;
  background-color: #9e9e9e;
  transition: none;
}

@media (min-width: 1700px) {
  .db-item .icon:before {
    width: 45px;
    height: 45px;
  }
}

.db-item .fz-C {
  margin-bottom: 0;
  margin-top: 12px;
  text-align: right;
}

.db-item.small {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.db-item.small .fz-C {
  margin-top: 0;
  margin-bottom: 2px;
  white-space: nowrap;
}

@media (hover: hover) {
  .db-item:hover {
    background: linear-gradient(#62B8EE, #559ecc);
    color: #fff;
    border-color: #62B8EE;
  }

  .db-item:hover .icon:before {
    background-color: #fff;
  }
}

.dashboard .full {
  width: 100%;
  padding: 0 40px;
}

.mt-item {
  border: 1px solid #E0E0E0;
  border-radius: 16px;
  overflow: hidden;
}

.mt-item .mt-header {
  background-color: #C9E5F6;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt-item .mt-footer {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FAFAFA;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -3px 8px;
}

.mt-item .mt-footer .btn {
  min-width: 120px;
}

.mt-item .mt-body {
  height: 50vh;
  min-height: 300px;
  overflow-y: auto;
}

.mt-pool {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.mt-pool .row-33 {
  width: calc(33.33% - 30px);
  margin: 0 15px 30px;
}

.mt-pool .row-50 {
  width: calc(50% - 30px);
  margin: 0 15px 30px;
}

.for-4-1 .list-table {
  min-width: 2600px;
  position: initial;
}

.for-4-1 .list-table-wrapper {
  height: auto;
  max-height: calc(100vh - 340px);
}

.for-4-1 .list-table .lt-row:last-of-type {
  margin-bottom: 20px;
}

.for-4-1 .list-table .lt-row>div {
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 1380px) {
  .for-4-1 .list-table .lt-row>div {
    padding-left: 6px;
    padding-right: 6px;
  }

  .for-4-1 .list-table .lt-row>div:first-of-type {
    padding-left: 12px;
  }
}

@media (min-width: 1380px) {
  .for-4-1 .list-table .lt-row>div {
    padding-left: 6px;
    padding-right: 6px;
  }
}


.for-4-1 .fixed-1 {
  width: 60px;
  overflow: hidden;
  flex: initial;
}

.for-4-1 .fixed-2 {
  width: 180px;
  overflow: hidden;
  flex: initial;
}

.for-4-1 .fixed-3 {
  width: 100px;
  overflow: hidden;
  flex: initial;
}

.for-4-1 .flex-1 {
  min-width: 70px;
}

.for-4-1 .fixed-4 {
  width: 220px;
  overflow: hidden;
  flex: initial;
}

.form-item.for-4521 {
  justify-content: center;
}

.form-item.for-4521 .field {
  width: initial !important;
  color: #616161;
}

.form-item.for-4521 .value {
  width: initial !important;
  flex: initial;
}

.form-item.for-4521 .value>div {
  width: max-content;
  flex: initial;
}

.dev {
  margin-top: 60px;
}

.dev .container {
  background-color: #efefef;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.dev .container:after {
  content: "白色：figma上標示  |  紅色：figma下方標註  |  灰色：曾經在前面單元出現過 ";
  display: block;
  color: #666;
  letter-spacing: 0.5px;
  font-size: .875rem;
}

.dev h3 {
  margin-bottom: 1em;
}

.dev .btn {
  height: 30px;
  font-size: 14px;
  width: auto;
  min-width: 120px;
  min-width: auto;
  border: 2px solid #666;
  background-color: #fff;
  border-radius: 3px;
  margin: 0 15px 15px 0;
}

.dev .btn span {
  font-family: "PingFang", "PingFangTC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #666;
}

.dev .btn.red {
  background-color: #FF0000;
  border-color: #FF0000;
}

.dev .btn.red span {
  color: #fff;
}

.dev .btn.ed {
  background-color: #888;
  border-color: #888;
}

.dev .btn.ed span {
  color: #eee;
}

.dev .btn:hover,
.dev .btn:active {
  background-color: #333;
  border-color: #333;
}

.dev .btn:hover span,
.dev .btn:active span {
  color: #fff;
}

.for-user-management .title-area .container {
  justify-content: space-between;
}

.for-user-management .title-area .fz-B {
  margin-bottom: 0;
}

.for-user-management .left {
  padding: 16px;
  padding-bottom: 0;
  padding-right: 0;
}

@media (min-width: 980px) {
  .for-user-management .left {
    width: 43%;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    height: calc(100vh - 273px);
    margin-top: 8px;
    overflow: hidden;
  }
}

@media (min-width: 1380px) {
  .for-user-management .left {
    width: 43%;
  }
}

@media (min-width: 1700px) {
  .for-user-management .left {
    width: 45%;
    border-radius: 12px;
  }
}

.for-user-management .left .title {
  display: block;
}

.for-user-management .left .title .fz-C {
  margin-bottom: 1em;
}

.for-user-management .left .scrollbar {
  height: calc(100% - 115px);
}

input[type="text"]:disabled.file-input-front {
  background-color: white;
  color: black;
}

@media (min-width: 980px) {
  .for-user-management .right {
    width: 55%;
    margin-left: 2%;
    margin-top: 8px;
  }
}

@media (min-width: 1180px) {
  .for-user-management .right {
    width: 53%;
    margin-left: 4%;
  }
}

@media (min-width: 1380px) {
  .for-user-management .right {
    margin-left: 8%;
    width: 49%;
  }
}

@media (min-width: 1700px) {
  .for-user-management .right {
    margin-left: 6%;
    width: 49%;
  }
}

.for-user-management .right .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
}

.for-user-management .right .title .form-item {
  width: 60%;
  margin: 0;
}

@media (min-width: 1380px) {
  .for-user-management .right .title .form-item {
    width: 50%;
  }
}

.for-user-management .right .title .fz-C {
  margin-bottom: 0;
}

.for-user-management .right .scrollbar {
  height: calc(100vh - 322px);
}

.for-user-management .right .list-table {
  border: none;
}

.for-user-management .right .list-table .lt-th {
  position: sticky;
  top: 0;
  z-index: 3;
}

.for-user-management .right .list-table .lt-row {
  border-left: none;
  border-right: none;
}

.for-user-management .copyright {
  margin-top: 24px;
}

.for-user-management .scrollbar::-webkit-scrollbar-track {
  border: 3px solid #FAFAFA;
}

.um-item {
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 6px;
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 16px);
}

.um-item .um-info {
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
  border-bottom: 1px solid #E0E0E0;
  width: 100%;
}

@media (min-width: 1380px) {
  .um-item .um-info {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.um-item .um-info .email {
  font-weight: bold;
}

.um-item .um-list {
  width: 100%;
  padding: 12px;
}

@media (min-width: 1380px) {
  .um-item .um-list {
    width: 50%;
  }
}

.um-item .um-fn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 12px;
  width: 100%;
}

@media (min-width: 1380px) {
  .um-item .um-fn {
    width: 50%;
  }
}

.container .react-datepicker-manager,
.container .react-datepicker-wrapper,
.container .react-datepicker__input-container {
  width: 100%;
}

.noposition {
    position: unset !important;
}

.ver {
    display: block;
    position: fixed;
    bottom: 10px;
    right: 50px;
    background-color: #337ab7;
    opacity: 60%;
    padding: 2px;
    color: #fff;
    border-radius: 6px;
}